import api from '@api'

import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const transform = (item) => ({
  id: item.id,
  name: item.name,
  description: item.description,
  content: item.content,
  createdAt: item.createdTime,
})

const transformForServer = (item) => ({
  id: item.id,
  name: item.name,
  description: item.description,
  content: item.content,
})

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function searchCustomScriptApi(
  filter,
  limit,
  offset,
  additionalUrlParams = {}
) {
  return api
    .post(
      `/customscript/search/byqual`,
      {
        qualDetails:
          filter.ids || filter.name ? buildQualifications(filter) : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalUrlParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: data.objectList ? (data.objectList || []).map(transform) : [],
        total: data.totalCount,
      }
    })
}

export function createCustomScriptApi(data) {
  return api
    .post(`/customscript`, transformForServer(data), {
      message: __rootT('added_successfully', {
        resource: __rootTc('custom_script'),
      }),
    })
    .then(transform)
}

export function getCustomScriptApi(id) {
  return api.get(`/customscript/${id}`).then(transform)
}

export function updateCustomScriptApi(data) {
  return api
    .patch(`/customscript/${data.id}`, transformForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('custom_script'),
      }),
    })
    .then(transform)
}

export function deleteCustomScriptApi(id) {
  return api.delete(`/customscript/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('custom_script'),
    }),
  })
}
